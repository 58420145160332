import { PublicClip } from '@air/api';
import { AssetCustomFieldValue, CustomFieldTypeName } from '@air/api/types';
import { useCallback } from 'react';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { PublicAssetCustomFieldDateInput } from '~/components/RightPanel/CustomFields/PublicAssetCustomFieldDateInput';
import { PublicAssetCustomFieldPlainTextInputTableCell } from '~/components/RightPanel/CustomFields/PublicAssetCustomFieldPlainTextInputTableCell';
import { PublicAssetCustomFieldSelect } from '~/components/RightPanel/CustomFields/PublicAssetCustomFieldSelect';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';
import { canChangeAssetCustomFields } from '~/utils/permissions/assetPermissions';

import { BaseCustomFieldTableCell } from './BaseCustomFieldTableCell';
import { AssetCustomFieldTableCellProps } from './types';

export const PublicAssetCustomFieldTableCell = ({
  clip,
  customFieldName,
  customFieldId,
}: AssetCustomFieldTableCellProps<PublicClip>) => {
  const actualCustomFieldValue = clip.customFields?.find((customField) => customField.id === customFieldId);
  const assignedCustomFieldValue = actualCustomFieldValue as AssignedCustomFieldValue | undefined;
  const { permissions } = usePublicPermissions();
  const canEditCustomFields = canChangeAssetCustomFields(permissions);
  const { customFields } = usePublicCustomFieldsContext();
  const customField = customFields?.find((field) => field.id === customFieldId);

  const renderEditableCustomField = useCallback(
    (closeEditor: () => void) => {
      if (!customField) return null;
      if (!canEditCustomFields) return null;
      const assetCustomFieldValue: AssetCustomFieldValue = actualCustomFieldValue || {
        ...customField,
        assetId: clip.assetId,
        value: null,
        values: null,
      };
      switch (customField.type.title) {
        case CustomFieldTypeName.singleSelect:
        case CustomFieldTypeName.multiSelect: {
          return (
            <PublicAssetCustomFieldSelect
              autoFocus
              assetId={clip.assetId}
              canEditCustomFields={canEditCustomFields}
              onOptionsClose={closeEditor}
              field={assetCustomFieldValue}
              trackingLocation="table-cell"
            />
          );
        }
        case CustomFieldTypeName.plainText: {
          return (
            <PublicAssetCustomFieldPlainTextInputTableCell
              assetId={clip.assetId}
              field={assetCustomFieldValue}
              closeEditor={closeEditor}
              trackingLocation="table-cell"
            />
          );
        }
        case CustomFieldTypeName.date: {
          return (
            <PublicAssetCustomFieldDateInput
              isEditing
              assetId={clip.assetId}
              canEditCustomFields={canEditCustomFields}
              field={assetCustomFieldValue}
              onBlur={closeEditor}
              trackingLocation="table-cell"
            />
          );
        }
      }
    },
    [customField, canEditCustomFields, actualCustomFieldValue, clip.assetId],
  );
  return (
    <BaseCustomFieldTableCell
      customFieldName={customFieldName}
      assignedCustomFieldValue={assignedCustomFieldValue}
      renderEditableCustomField={canEditCustomFields ? renderEditableCustomField : undefined}
    />
  );
};
