import { formatDuration } from '@air/utilities';
import { memo } from 'react';
import { useResizeDetector } from 'react-resize-detector';

export type VideoStandardTimeProps = {
  currentTime: number;
  totalTime?: number;
};

export const VideoStandardTime = memo(({ currentTime, totalTime }: VideoStandardTimeProps) => {
  const { ref: resizeRef, width } = useResizeDetector({
    handleHeight: false,
  });

  return (
    <div
      className="flex items-center gap-1 text-14 text-grey-11"
      data-testid="VIDEO_STANDARD_TIME"
      style={{
        fontFeatureSettings: "'tnum' on",
      }}
    >
      <span
        className="text-right font-medium text-grey-12"
        data-testid="VIDEO_STANDARD_TIME_CURRENT"
        style={{ minWidth: width }}
      >
        {formatDuration(currentTime)}
      </span>
      <span>/</span>
      <span data-testid="VIDEO_STANDARD_TIME_TOTAL" ref={resizeRef}>
        {totalTime ? formatDuration(totalTime) : '-'}
      </span>
    </div>
  );
});

VideoStandardTime.displayName = 'VideoStandardTime';
