import { Board, Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import {
  EditableAssetModalTitle,
  EditableAssetModalTitleProps,
} from '~/components/AssetModal/components/EditableAssetModalTitle';
import { validateAssetTitle } from '~/components/AssetModal/Header/components/EditableAssetModalHeaderTitle';
import { ASSET_TITLE } from '~/constants/testIDs';
import { centralizedClipBoardInfoSelector, CentralizedClipSelectors } from '~/store/centralizedClip/selectors';

export type MobileHeaderProps = {
  onClose?: () => void;
  onUpdateTitle?: EditableAssetModalTitleProps['onSubmit'];
};

export const MobileHeader = memo(({ onClose, onUpdateTitle }: MobileHeaderProps) => {
  const title = useSelector(CentralizedClipSelectors.displayTitle);

  const { current, others } = useSelector(centralizedClipBoardInfoSelector);

  return (
    <div className="flex h-[80px] w-full shrink-0 items-center justify-start bg-grey-1 p-3">
      <div className="flex h-full flex-col justify-center gap-1 px-3">
        {!!onUpdateTitle ? (
          <div className="-ml-2.5 flex">
            <EditableAssetModalTitle
              className="grow-0"
              defaultValue={title}
              onSubmit={onUpdateTitle}
              validate={validateAssetTitle}
            />
          </div>
        ) : (
          <h1
            className="flex h-9 w-full shrink-0 items-center truncate text-18 font-semibold text-grey-11"
            data-testid={ASSET_TITLE}
          >
            {title}
          </h1>
        )}
        {(!!current || !!others.length) && (
          <div className="flex items-center justify-start">
            {!!current && (
              <div className="text-14 text-grey-11">
                {'in'}
                <span className="ml-1 font-medium text-grey-12">{current.title}</span>
              </div>
            )}
            {!!others.length && (
              <>
                {!!current && <div className="text-14 text-grey-12">・</div>}
                <Board className="size-4 text-grey-12" />
                <div className="ml-1 text-14 font-medium text-grey-11">{others.length}</div>
              </>
            )}
          </div>
        )}
      </div>
      {!!onClose && (
        <IconButton
          icon={Close}
          appearance="ghost"
          color="grey"
          size="extra-large"
          onClick={onClose}
          className="ml-auto"
          label="Close modal"
        />
      )}
    </div>
  );
});

MobileHeader.displayName = 'MobileHeader';
