import { AirActionTrackingLocation } from '@air/analytics';
import { BoardCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import { CustomFieldPlainTextTableCellInput } from '~/components/TableView/TableRow/CustomFieldTableCell/CustomFieldPlainTextTableCellInput';
import { useUpdatePublicItemsCustomFieldValue } from '~/utils/CustomFields/useUpdatePublicItemsCustomFieldValue';

export interface PublicBoardCustomFieldPlainTextInputTableCellProps {
  field: BoardCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  boardId: string;
  closeEditor: () => void;
}

export const PublicBoardCustomFieldPlainTextInputTableCell = memo(
  ({ field, trackingLocation, boardId, closeEditor }: PublicBoardCustomFieldPlainTextInputTableCellProps) => {
    const { updatePublicItemsCustomFieldValue } = useUpdatePublicItemsCustomFieldValue();

    const updateCustomFieldValue = useCallback(
      async (value: string) => {
        await updatePublicItemsCustomFieldValue({
          boardIds: [boardId],
          baseCustomField: field,
          nextPlainTextValue: value,
          trackingLocation,
        });
      },
      [boardId, updatePublicItemsCustomFieldValue, field, trackingLocation],
    );

    return (
      <CustomFieldPlainTextTableCellInput
        onBlur={closeEditor}
        defaultValue={field.plainTextValue}
        onSubmit={updateCustomFieldValue}
        className="-mt-1"
        fieldName={field.name}
      />
    );
  },
);

PublicBoardCustomFieldPlainTextInputTableCell.displayName = 'PublicBoardCustomFieldPlainTextInputTableCell';
