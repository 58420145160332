import { Air } from '@air/next-icons';
import Link from 'next/link';
import { memo, ReactNode, useMemo } from 'react';

import { PublicSignUpButton } from '~/components/PublicSignUpButton';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { Routes } from '~/constants/routes';
import { PUBLIC_TOP_BAR, PUBLIC_TOP_BAR_LOGO } from '~/constants/testIDs';
import { useLaunchDarklyFlags } from '~/hooks/useLaunchDarklyFlags';

export interface PublicBoardTopBarProps {
  workspaceInfo?: ReactNode;
  accountInfo?: ReactNode;
}

export const PUBLIC_TOP_BAR_HEIGHT = 56;

export const PublicTopBar = memo(({ workspaceInfo, accountInfo }: PublicBoardTopBarProps) => {
  const { workspaceId } = usePublicWorkspace();
  const { hideShortUrlSignUpButton, whiteLabelWorkspaces } = useLaunchDarklyFlags();

  const shouldHideLogo = useMemo(() => {
    return whiteLabelWorkspaces?.includes(workspaceId);
  }, [whiteLabelWorkspaces, workspaceId]);

  const shouldHideSignUpButton = useMemo(() => {
    return hideShortUrlSignUpButton?.includes(workspaceId);
  }, [hideShortUrlSignUpButton, workspaceId]);

  return (
    <div className="relative flex h-14 items-center justify-between bg-grey-3 px-3" data-testid={PUBLIC_TOP_BAR}>
      <div>{workspaceInfo}</div>

      <div className="flex items-center gap-4">
        {accountInfo ? accountInfo : !shouldHideSignUpButton ? <PublicSignUpButton /> : undefined}
        {!shouldHideLogo && (
          <Link href={Routes.root}>
            <Air className="w-[48px] text-blue-9" data-testid={PUBLIC_TOP_BAR_LOGO} />
          </Link>
        )}
      </div>
    </div>
  );
});

PublicTopBar.displayName = 'PublicTopBar';
