import { useHotkeys } from 'react-hotkeys-hook';

import { useVideoTimeFormatContext } from '~/providers/VideoTimeFormatProvider';
import ClipUtils from '~/utils/ClipUtils';

const VIDEO_SHORTCUT_SCOPE = 'video';

export type UseVideoShortcutsParams = {
  onUpdateFrame?: (frames: number) => void;
  onDecreasePlaybackStep?: () => void;
  onIncreasePlaybackStep?: () => void;
  onChangeVolume: (volume: number) => void;
  onToggleMute: () => void;
  onToggleVideoState: () => void;
  videoElement: HTMLVideoElement | null;
};

export const useVideoShortcuts = ({
  onUpdateFrame,
  onDecreasePlaybackStep,
  onIncreasePlaybackStep,
  onChangeVolume,
  onToggleMute,
  onToggleVideoState,
  videoElement,
}: UseVideoShortcutsParams) => {
  const { format } = useVideoTimeFormatContext();

  useHotkeys(
    'shift+left',
    () => {
      onUpdateFrame?.(-1);
    },
    {
      description: 'Jump 1 frame backwards',
      enabled: !!onUpdateFrame,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [format, onUpdateFrame],
  );

  useHotkeys(
    'shift+right',
    () => {
      onUpdateFrame?.(1);
    },
    {
      description: 'Jump 1 frame forward',
      enabled: !!onUpdateFrame,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [format, onUpdateFrame],
  );

  useHotkeys(
    'down',
    () => {
      if (videoElement) {
        ClipUtils.changeVideoVolume(videoElement, -0.1);
        onChangeVolume(videoElement.volume);
      }
    },
    {
      description: 'Decrease volume by 10%',
      enabled: !!videoElement,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [onChangeVolume, videoElement],
  );

  useHotkeys(
    'up',
    () => {
      if (videoElement) {
        ClipUtils.changeVideoVolume(videoElement, 0.1);
        onChangeVolume(videoElement.volume);
      }
    },
    {
      description: 'Increase volume by 10%',
      enabled: !!videoElement,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [onChangeVolume, videoElement],
  );

  useHotkeys(
    '1,2,3,4,5,6,7,8,9,0',
    ({ key }) => {
      const parsedKey = parseInt(key, 10);

      if (Number.isInteger(parsedKey) && videoElement) {
        const percentage = parsedKey / 10;
        videoElement.currentTime = (videoElement.duration || 0) * percentage;
      }
    },
    {
      description: 'Seek to a specific percentage of the video',
      enabled: !!videoElement,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [videoElement],
  );

  useHotkeys(
    'm',
    () => {
      onToggleMute();
    },
    {
      description: 'Toggle mute',
      enabled: !!videoElement,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [onToggleMute],
  );

  useHotkeys(
    'space,k',
    () => {
      onToggleVideoState();
    },
    {
      description: 'Toggle video state',
      enabled: true,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [onToggleVideoState],
  );

  useHotkeys(
    'j',
    () => onDecreasePlaybackStep?.(),
    {
      enabled: !!onDecreasePlaybackStep,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [onDecreasePlaybackStep],
  );
  useHotkeys(
    'l',
    () => onIncreasePlaybackStep?.(),
    {
      enabled: !!onIncreasePlaybackStep,
      scopes: [VIDEO_SHORTCUT_SCOPE],
      preventDefault: true,
    },
    [onIncreasePlaybackStep],
  );
};
