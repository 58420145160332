import { type Clip } from '@air/api/types';
import noop from 'lodash/noop';
import { createContext, type ReactNode, useCallback, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import { VIDEO_TIME_FORMAT } from '~/constants/localStorageKeys';

export enum VideoTimeFormat {
  Standard = 'standard',
  Frames = 'frames',
  Timecode = 'timecode',
}

export type VideoTimeFormatContextType = {
  format: VideoTimeFormat;
  handleUpdateFormat: (format: VideoTimeFormat) => void;
};

const DEFAULT_VALUE = {
  format: VideoTimeFormat.Standard,
  handleUpdateFormat: noop,
};

const VideoTimeFormatContext = createContext<VideoTimeFormatContextType>(DEFAULT_VALUE);

export type VideoTimeFormatProviderProps = {
  children: ReactNode;
  clipId: Clip['id'];
};

export const VideoTimeFormatProvider = ({ children, clipId }: VideoTimeFormatProviderProps) => {
  const [values, setValues] = useLocalStorage<{ [key: string]: VideoTimeFormat }>(VIDEO_TIME_FORMAT, {});

  const format = useMemo(() => values?.[clipId] ?? VideoTimeFormat.Standard, [values, clipId]);

  const handleUpdateFormat = useCallback(
    (format: VideoTimeFormat) => {
      setValues({ ...values, [clipId]: format });
    },
    [clipId, setValues, values],
  );

  const value = useMemo<VideoTimeFormatContextType>(() => {
    return {
      format,
      handleUpdateFormat,
    };
  }, [format, handleUpdateFormat]);

  return <VideoTimeFormatContext.Provider value={value}>{children}</VideoTimeFormatContext.Provider>;
};

export const useVideoTimeFormatContext = () => {
  const context = useContext(VideoTimeFormatContext);

  if (context === DEFAULT_VALUE) {
    throw new Error('useVideoTimeFormatContext must be used within a VideoTimeFormatProvider');
  }

  return context;
};
