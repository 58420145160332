import { memo, useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';
import { usePrevious } from 'react-use';

import { formatTimecodeValue } from '~/utils/VideoUtils';

export type VideoTimecodeFramesProps = {
  frames: number;
  frameCount: number;
};

export const VideoTimecodeFrames = memo(({ frames, frameCount }: VideoTimecodeFramesProps) => {
  const countRef = useRef<HTMLDivElement>(null);
  const { reset, update } = useCountUp({
    ref: countRef,
    duration: 1,
    start: 0,
    end: frameCount || 0,
    formattingFn: formatTimecodeValue,
  });
  const prevFrames = usePrevious(frames);

  useEffect(() => {
    if (frames && prevFrames && frames > prevFrames) {
      update(frames);
    } else if (frames) {
      /**
       * When the next value is lower than the previous value, we need to reset the count up before
       * updating the value to prevent the count up from counting down.
       */
      reset();
      update(frames);
    }
  }, [frames, prevFrames, reset, update]);

  return <span ref={countRef} />;
});

VideoTimecodeFrames.displayName = 'VideoTimecodeFrames';
