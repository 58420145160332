import { memo, useMemo } from 'react';

import { Panel } from '~/components/AssetModal/Panel/Panel';
import { CommentsTabButton } from '~/components/AssetModal/Panel/Tabs/components/CommentsTabButton';
import { CustomFieldsTabButton } from '~/components/AssetModal/Panel/Tabs/components/CustomFieldsTabButton';
import { InfoTabButton } from '~/components/AssetModal/Panel/Tabs/components/InfoTabButton';
import { PublicAssetModalInfoPanel } from '~/components/PublicAssetModal/components/PublicAssetModalInfoPanel';
import { PublicCustomFieldsPanelContainer } from '~/components/PublicAssetModal/shared/PublicCustomFieldsPanelContainer';
import { PublicBoardAssetCommentsPanelContainer } from '~/components/PublicBoardAsset/components/PublicBoardAssetCommentsPanelContainer';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useOpenPublicCommentsPanelOnMount } from '~/hooks/useOpenPublicCommentsPanelOnMount';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { canSeeAssetCustomFields, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export interface PublicBoardAssetModalPanelProps {
  showArrows: boolean;
}

export const PublicBoardAssetModalPanel = memo(({ showArrows }: PublicBoardAssetModalPanelProps) => {
  const { panel } = useAssetModalPanelContext();

  const { permissions } = usePublicPermissions();
  const canViewComments = canViewAssetDiscussions(permissions);
  const canViewCustomFields = canSeeAssetCustomFields(permissions);

  useOpenPublicCommentsPanelOnMount();

  const renderPanel = useMemo(() => {
    switch (panel) {
      case AssetModalPanel.FIELDS:
        return canViewCustomFields ? <PublicCustomFieldsPanelContainer /> : null;

      case AssetModalPanel.INFO:
        return <PublicAssetModalInfoPanel permissions={permissions} />;

      case AssetModalPanel.COMMENTS:
        return canViewComments ? <PublicBoardAssetCommentsPanelContainer /> : null;
      default:
    }
  }, [canViewComments, canViewCustomFields, panel, permissions]);

  const tabs = useMemo(
    () => (
      <>
        {canViewComments && <CommentsTabButton />}
        {canViewCustomFields && <CustomFieldsTabButton />}
        <InfoTabButton />
      </>
    ),
    [canViewComments, canViewCustomFields],
  );

  return <Panel tabsBottomPadding={!!showArrows ? 80 : undefined} panel={renderPanel} tabs={tabs} />;
});

PublicBoardAssetModalPanel.displayName = 'PublicBoardAssetModalPanel';
