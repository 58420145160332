import { useEffect, useState } from 'react';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { QueryParamNames } from '~/constants/search';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { setQueryParams } from '~/utils/PathUtils';
import { canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export const useOpenPublicCommentsPanelOnMount = () => {
  const [isMounted, setIsMounted] = useState(false);
  const { permissions } = usePublicPermissions();
  const canViewDiscussions = canViewAssetDiscussions(permissions);

  /**
   * We have an isMounted check here because `setQueryParams` uses the
   * singleton router which requires the component to be mounted on the
   * client.
   */
  useEffect(() => {
    setIsMounted(true);
  }, []);

  /**
   * If a user can view discussions and no tab is selected, open the comments tab
   * by default.
   */
  useEffect(() => {
    /**
     * We use `URLSearchParams` instead of the `activeTabParamSelector` selector
     * because `activeTabParamSelector` is always `null` on mount even if a value
     * is present in the URL.
     */
    const tab = new URLSearchParams(window.location.search).get(QueryParamNames.tab);

    if (canViewDiscussions && !tab && isMounted) {
      setQueryParams({ tab: AssetModalPanel.COMMENTS });
    }
  }, [canViewDiscussions, isMounted]);
};
