import {
  useTrackClickedVideoFullScreenButton,
  useTrackClickedVideoLoopButton,
  useTrackClickedVideoQualityButton,
  useTrackClickedVideoSpeedButton,
  useTrackEnteredFullScreen,
  useTrackExitedFullScreen,
  useTrackSelectedComment,
} from '@air/analytics';
import { VideoSeekbar, VideoTimestamp } from '@air/component-video';
import { useShowOnActivity } from '@air/hook-use-show-on-activity';
import { ChevronLeft, ChevronRight, FilledPlay } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Spinner } from '@air/primitive-spinner';
import { Tooltip } from '@air/primitive-tooltip';
import { useBreakpointsContext } from '@air/provider-media-query';
import { formatDuration } from '@air/utilities';
import classNames from 'classnames';
import Hls from 'hls.js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { minBy } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { useMountedState, useUnmount } from 'react-use';

import { Annotations } from '~/components/Annotations/Annotations';
import { BIG_PLAY_Z_INDEX } from '~/components/AssetModal/shared/constants';
import { useAssetHotkeys } from '~/components/AssetModal/Visualizer/shared/useAssetHotkeys';
import { VideoPlaybackSpeedAlert } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/components/VideoPlaybackSpeedAlert';
import { VideoTimeFormatMenu } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/components/VideoTimeFormatMenu';
import { SHORTCUT_PLAYBACK_SPEEDS } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/constants/playbackSpeed';
import { useVideoCurrentTimeObserver } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/hook/useVideoCurrentTimeObserver';
import { useVideoPlayback } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/hook/useVideoPlayback';
import { useVideoRewind } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/hook/useVideoRewind';
import { useVideoSeek } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/hook/useVideoSeek';
import { useVideoShortcuts } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/hook/useVideoShortcuts';
import { FilePlayer } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/shared/FilePlayer';
import { QueryParamNames } from '~/constants/search';
import { VIDEO, VIDEO_CONTROLS_WRAPPER } from '~/constants/testIDs';
import { useFullScreen } from '~/hooks/useFullScreen';
import { usePersistedState } from '~/hooks/usePersistedState';
import usePrevious from '~/hooks/usePrevious';
import { useAnnotationContext, useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import {
  activeAnnotationSelector,
  isAnnotationsEnabledSelector,
} from '~/providers/AnnotationProvider/annotationProviderUtils';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { useVideoControlContext } from '~/providers/VideoControlsProvider';
import { useVideoTimeFormatContext, VideoTimeFormat } from '~/providers/VideoTimeFormatProvider';
import { useVideoTimestampObserverContext } from '~/providers/VideoTimestampObserverProvider';
import { useVideoTimestampContext } from '~/providers/VideoTimestampProvider';
import { CentralizedClip } from '~/store/centralizedClip/types';
import { activeDiscussionIdParamSelector, activeTimestampParamSelector } from '~/store/router/selectors';
import ClipUtils from '~/utils/ClipUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { loadHls } from '~/utils/HlsUtils';
import { setQueryParams } from '~/utils/PathUtils';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { useFullscreenContext } from '../../../shared/context/FullscreenContext';
import { TimestampMarkers } from '../shared/types';
import { FullScreenButton } from './components/FullScreenButton';
import { LoopVideoButton } from './components/LoopVideoButton';
import { PlaybackSpeedOptions } from './components/PlaybackSpeedOptions';
import { PlayPauseButton } from './components/PlayPauseButton';
import { ProcessingMessage } from './components/ProcessingMessage';
import { VideoQualityOptions } from './components/VideoQualityOptions';
import { ACTIVE_TIMESTAMP_MARKER_Z_INDEX } from './shared/constants';
import { VolumeControl } from './VolumeControl/VolumeControl';

const _iconMap = {
  play: 'Play',
  pause: 'Pause',
  null: '',
};

const FULLSCREEN_SEEKBAR_MAXWIDTH = 824;

export interface VideoVisualizerInnerProps {
  clip: CentralizedClip;
  markers?: TimestampMarkers;
  canEditDiscussions?: boolean;
  onMarkerClick?: (markerTimestamp: number) => void;
}

interface State {
  currentIconIndicator: keyof typeof _iconMap;
  duration: number;
  isLoadingVideo: boolean;
  isPlaying: boolean;
  isSeeking: boolean;
  processedPercentage: number;
  progressPercentage: number;
  showLoadingIndicator: boolean;
  shouldLoopVideo: boolean;
}

export const VideoVisualizerInner = memo(
  ({ clip, markers, onMarkerClick: _onMarkerClick, canEditDiscussions }: VideoVisualizerInnerProps) => {
    const { videoTimestamp: hasVideoTimestampFlag } = useFlags();
    const { setIsFullscreen } = useFullscreenContext();
    const [shouldResetVideo, setShouldResetVideo] = useState(true);
    const [
      {
        duration,
        isLoadingVideo,
        isPlaying,
        isSeeking,
        processedPercentage,
        progressPercentage,
        showLoadingIndicator,
        shouldLoopVideo,
      },
      setState,
    ] = useState<State>({
      currentIconIndicator: 'null',
      duration: clip.duration || 0,
      isLoadingVideo: false,
      isPlaying: false,
      isSeeking: false,
      processedPercentage: 0,
      progressPercentage: 0,
      showLoadingIndicator: false,
      shouldLoopVideo: false,
    });

    const loadVideoTimeout = useRef(0);
    const { isAboveMediumScreen } = useBreakpointsContext();
    const { showPanel } = useAssetModalPanelContext();
    const { setVideoEl } = useVideoControlContext();
    const { handleSetCurrentTime } = useVideoTimestampContext();
    const activeTimestamp = useSelector(activeTimestampParamSelector);
    const activeDiscussionId = useSelector(activeDiscussionIdParamSelector);
    const isViewingAnnotatedComment = !!activeDiscussionId;
    const { trackClickedVideoFullScreenButton } = useTrackClickedVideoFullScreenButton();
    const { trackClickedVideoLoopButton } = useTrackClickedVideoLoopButton();
    const { trackClickedVideoQualityButton } = useTrackClickedVideoQualityButton();
    const { trackClickedVideoSpeedButton } = useTrackClickedVideoSpeedButton();
    const { trackEnteredFullScreen } = useTrackEnteredFullScreen();
    const { trackExitedFullScreen } = useTrackExitedFullScreen();
    const { trackSelectedComment } = useTrackSelectedComment();
    const isProcessing = ClipUtils.isProcessing(clip.status);
    const isMounted = useMountedState();
    const {
      height: measuredHeight,
      width: measuredWidth,
      ref: videoContainer,
    } = useResizeDetector<HTMLDivElement>({
      handleHeight: true,
    });

    const clientHeight = measuredHeight || 0;
    const clientWidth = measuredWidth || 0;

    const hlsRef = useRef<Hls | null>(null);
    const outerContainer = useRef<HTMLDivElement | null>(null);
    const videoElement = useRef<HTMLVideoElement | null>(null);
    const controlsRef = useRef<HTMLDivElement | null>(null);
    const [isFullScreen, toggleFullScreen] = useFullScreen(outerContainer);
    const canDrawAnnotation = !!canEditDiscussions && isAboveMediumScreen && !isFullScreen;
    const [muted, setMuted] = usePersistedState<boolean>('air-volume-muted', false);
    const [volume, setVolume] = usePersistedState<number>('air-volume', 0.8);
    const { setAnnotationsEnabled, setActiveAnnotation } = useAnnotationContext();
    const activeAnnotation = useAnnotationContextSelector(activeAnnotationSelector);
    const isAnnotationsEnabled = useAnnotationContextSelector(isAnnotationsEnabledSelector);
    const [currentPlaybackSpeed, setCurrentPlaybackSpeed] = usePersistedState<number>('air-playback-speed', 1);
    const [resolutionOptions, setResolutionOptions] = useState<{ width: number; height: number }[]>([]);
    const [currentResolutionIndex, setCurrentResolutionIndex] = useState<number>(-1);
    const closetPlaybackSpeed = minBy(SHORTCUT_PLAYBACK_SPEEDS, (speed) => Math.abs(speed - currentPlaybackSpeed));
    const { onDecreasePlaybackStep, onIncreasePlaybackStep, onResetPlaybackStep, playbackSpeed, showPlaybackAlert } =
      useVideoPlayback({
        defaultStepIndex: closetPlaybackSpeed
          ? SHORTCUT_PLAYBACK_SPEEDS.indexOf(closetPlaybackSpeed)
          : SHORTCUT_PLAYBACK_SPEEDS.indexOf(1),
        isPlaying,
        steps: SHORTCUT_PLAYBACK_SPEEDS,
        videoElement: videoElement.current,
      });
    const { isRewinding } = useVideoRewind({
      onResetPlaybackStep,
      playbackSpeed,
      videoElement: videoElement.current,
    });
    const { updateTime } = useVideoTimestampObserverContext();
    const { startObservingCurrentTime, stopObservingCurrentTime } = useVideoCurrentTimeObserver({
      videoRef: videoElement,
      onTimeUpdate: updateTime,
      interval: 50,
    });
    const { format } = useVideoTimeFormatContext();

    const getShouldHideControls = useCallback(() => isFullScreen, [isFullScreen]);

    useShowOnActivity({ containerRef: videoContainer, displayRef: controlsRef, getShouldHide: getShouldHideControls });

    useAssetHotkeys({ openFullscreen: toggleFullScreen, isFullscreen: isFullScreen });

    const { height: vidHeight, width: vidWidth } = useResizeDetector<HTMLDivElement>({
      handleHeight: true,
      targetRef: videoElement,
    });

    const videoWidth = vidWidth || 0;
    const videoHeight = vidHeight || 0;
    // Player actions.
    const play = useCallback(() => {
      if (!isMounted()) return;

      /**
       * When the video is first played, we need to reset the current time to 0.
       * Since we're streaming the video, each time hls.js loads the video, it
       * starts the video from where the current stream is. This means that if
       * the user mounts and unmounts the video, it'll start at a different time
       * each time.
       */
      if (videoElement.current && shouldResetVideo && isProcessing) {
        videoElement.current.currentTime = 0;
        setShouldResetVideo(false);
      }

      const playPromise = videoElement.current && videoElement.current.play();
      if (playPromise) {
        playPromise.catch(() => {
          // https://stackoverflow.com/a/53167783
          // autoplay is disabled
          isMounted() &&
            setState((prev) => ({
              ...prev,
              showLoadingIndicator: false,
            }));
        });
      }
    }, [isMounted, shouldResetVideo, isProcessing]);

    useEffect(() => {
      if (isPlaying) {
        setActiveAnnotation(undefined);
      }
    }, [isPlaying, setActiveAnnotation]);

    useEffect(() => {
      setIsFullscreen(isFullScreen);
      return () => {
        setIsFullscreen(false);
      };
    }, [isFullScreen, setIsFullscreen]);

    const pause = useCallback(() => {
      onResetPlaybackStep();
      if (videoElement.current && !videoElement.current.paused) {
        videoElement.current.pause();
      }
    }, [videoElement, onResetPlaybackStep]);

    const toggleMuted = useCallback(() => {
      if (!videoElement.current) return null;

      videoElement.current.muted = !videoElement.current.muted;
      if (videoElement.current.muted) {
        setMuted(true);
      } else {
        setMuted(false);
        setVolume(videoElement.current.volume);
      }
    }, [setMuted, setVolume, videoElement]);

    const loadVideo = useCallback(async () => {
      setState((prev) => ({
        ...prev,
        isPlaying: false,
        progressPercentage: 0,
        isLoadingVideo: true,
      }));

      loadVideoTimeout.current = window.setTimeout(() => {
        if (isLoadingVideo) {
          setState((prev) => ({
            ...prev,
            showLoadingIndicator: true,
          }));
        }
      }, 500);

      try {
        hlsRef.current?.destroy();
        hlsRef.current = await loadHls({
          videoElement: videoElement.current,
          src: clip.assets.video,
        });
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: 'Failed to load new Hls source in Video Visualiser',
          metadata: {
            key: 'Data',
            data: { video: clip },
          },
        });
      }

      if (!isMounted()) return;

      const possibleResolutions = (hlsRef.current?.levels || []).reduce(
        (acc, curr) => {
          acc.push({ width: curr.width, height: curr.height });
          return acc;
        },
        [] as { width: number; height: number }[],
      );

      setResolutionOptions(possibleResolutions);
      setCurrentResolutionIndex(-1);
      setQueryParams({ [QueryParamNames.timestamp]: 0 }, 'replace');
    }, [clip, isLoadingVideo, isMounted]);

    const clearLoadVideoTime = useCallback(() => {
      loadVideoTimeout.current && clearTimeout(loadVideoTimeout.current);
    }, []);

    useEffect(() => {
      loadVideo();
      /**
       * We only want to re-load the video here if we get a new video url back
       * (i.e. if we had played the streaming url while processing and clip has transcoded)
       */
    }, [clip.assets.video]); // eslint-disable-line react-hooks/exhaustive-deps

    // Load video
    const previousAsset = usePrevious(clip);
    const previousIsLoadingVideo = usePrevious(isLoadingVideo);
    useEffect(() => {
      if ((previousAsset && previousAsset.id) !== clip.id && clip.id) {
        loadVideo();
        const video = videoElement.current;
        if (video) {
          video.playbackRate = currentPlaybackSpeed;
        }
      }

      if (previousIsLoadingVideo && !isLoadingVideo) {
        setState((prev) => ({
          ...prev,
          showLoadingIndicator: false,
        }));
      }
    }, [
      clip,
      isLoadingVideo,
      loadVideo,
      previousAsset,
      previousIsLoadingVideo,
      progressPercentage,
      isPlaying,
      currentPlaybackSpeed,
      videoElement,
      isViewingAnnotatedComment,
    ]);

    // video property setters
    useEffect(() => {
      if (!videoElement.current) return;
      videoElement.current.muted = muted;
    }, [muted, videoElement]);
    useEffect(() => {
      if (!videoElement.current) return;
      videoElement.current.volume = volume;
    }, [videoElement, volume]);

    useEffect(() => {
      if (videoElement.current?.currentTime !== activeTimestamp && isViewingAnnotatedComment) {
        const video = videoElement.current;
        if (!video) {
          return;
        }

        video.currentTime = activeTimestamp || 0;

        if (!videoElement.current?.paused) {
          pause();
        }
      }
    }, [activeTimestamp, isViewingAnnotatedComment, videoElement, pause]);

    useEffect(() => {
      const element = videoElement.current;

      const onPlayListener = () => {
        startObservingCurrentTime();
        setState((prev) => ({
          ...prev,
          isLoadingVideo: false,
          isPlaying: true,
        }));
        setQueryParams({ [QueryParamNames.timestamp]: null, [QueryParamNames.discussionId]: null }, 'replace');
      };

      const onPauseListener = () => {
        stopObservingCurrentTime();

        setState((prev) => ({
          ...prev,
          isPlaying: false,
        }));
        setQueryParams({ [QueryParamNames.timestamp]: videoElement.current?.currentTime || 0 }, 'replace');
      };

      const onWaitingListener = () => {
        setState((prev) => ({
          ...prev,
          showLoadingIndicator: true,
        }));
      };

      const onTimeUpdateListener = () => {
        if (isSeeking || !videoElement.current) return;

        const { duration = 0 } = clip;
        let currentTime = videoElement.current.currentTime;
        if (Number.isNaN(currentTime) || (shouldResetVideo && isProcessing)) {
          currentTime = 0;
        }

        /**
         * We round the time here to fix a visual bug where
         * the current time and time remaining did not change simultaneously
         * This is only used for the times displayed on the clock
         */
        const roundedTimeElapsed = Math.round(currentTime);

        updateTime(currentTime);

        if (hasVideoTimestampFlag) {
          handleSetCurrentTime(videoElement.current?.currentTime || 0);
        }

        setState((prev) => ({
          ...prev,
          roundedCurrentTime: formatDuration(roundedTimeElapsed),
          roundedTimeRemaining: formatDuration(duration - roundedTimeElapsed),
          showLoadingIndicator: false,
          progressPercentage: currentTime / duration,
          formattedDuration: formatDuration(duration),
        }));
      };

      const onDurationChangeListener = () => {
        if (!videoElement.current || !isProcessing) return;

        const duration = clip.duration || 0;
        const processedDuration = videoElement.current.duration || 0;

        setState((prevState) => ({
          ...prevState,
          processedPercentage: processedDuration / duration,
        }));
      };

      if (element) {
        element.addEventListener('play', onPlayListener);
        element.addEventListener('pause', onPauseListener);
        element.addEventListener('waiting', onWaitingListener);
        element.addEventListener('timeupdate', onTimeUpdateListener);
        element.addEventListener('durationchange', onDurationChangeListener);
        if (shouldLoopVideo) {
          element.addEventListener('ended', play);
        }
      }

      return () => {
        if (element) {
          element.removeEventListener('play', onPlayListener);
          element.removeEventListener('pause', onPauseListener);
          element.removeEventListener('ended', play);
          element.removeEventListener('waiting', onWaitingListener);
          element.removeEventListener('timeupdate', onTimeUpdateListener);
          element.removeEventListener('durationchange', onDurationChangeListener);
        }
      };
    }, [
      clip,
      handleSetCurrentTime,
      hasVideoTimestampFlag,
      isProcessing,
      isSeeking,
      play,
      shouldLoopVideo,
      shouldResetVideo,
      startObservingCurrentTime,
      stopObservingCurrentTime,
      updateTime,
      videoElement,
    ]);

    useUnmount(() => {
      clearLoadVideoTime();
      hlsRef.current?.destroy();
    });

    const onToggleVideoState = useCallback(() => {
      (document.activeElement as HTMLElement).blur();

      onResetPlaybackStep();

      if (isPlaying || isRewinding) {
        pause();
      } else {
        play();
      }
    }, [isPlaying, isRewinding, onResetPlaybackStep, pause, play]);

    const onUpdateFrame = useCallback(
      (frames = 1) => {
        /**
         * For videos that are not processed to have a frameCount, we default the FPS to 24.
         */
        const fps = clip.duration && clip.frameCount ? clip.frameCount / clip.duration : 24;

        if (videoElement.current) {
          const framesInSeconds = frames * (1 / fps);

          ClipUtils.changeVideoTimeInSeconds(videoElement.current, framesInSeconds);
          setQueryParams({ [QueryParamNames.timestamp]: videoElement?.current.currentTime || 0 }, 'replace');
        }
      },
      [clip.duration, clip.frameCount],
    );

    useVideoShortcuts({
      onUpdateFrame,
      onDecreasePlaybackStep: videoElement.current?.currentTime !== 0 ? onDecreasePlaybackStep : undefined,
      onIncreasePlaybackStep,
      onChangeVolume: setVolume,
      onToggleMute: toggleMuted,
      onToggleVideoState,
      videoElement: videoElement.current,
    });

    const onVolumeChange = useCallback(
      (value: number) => {
        if (!videoElement.current) return null;

        videoElement.current.volume = value;
        setVolume(value);
      },
      [setVolume, videoElement],
    );

    const toggleLoopVideo = () => {
      setState((prev) => ({
        ...prev,
        shouldLoopVideo: !shouldLoopVideo,
      }));
      trackClickedVideoLoopButton();
    };

    const onSelectPlaybackSpeed = useCallback(
      (speed: number) => {
        onResetPlaybackStep();
        setCurrentPlaybackSpeed(speed);
        const video = videoElement.current;
        if (video) {
          video.playbackRate = speed;

          /**
           * If the video is rewinding, then it's technically "playing" so once a user
           * updates the playback speed manually, we need to set the video to a play
           * state.
           */
          if (isRewinding) {
            play();
          }
        }
        trackClickedVideoSpeedButton();
      },
      [isRewinding, onResetPlaybackStep, play, setCurrentPlaybackSpeed, trackClickedVideoSpeedButton],
    );

    const onSelectVideoResolution = (resIndex: number) => {
      if (hlsRef.current) {
        hlsRef.current.currentLevel = resIndex;
      }

      setCurrentResolutionIndex(resIndex);
      trackClickedVideoQualityButton();
    };

    const onToggleFullScreen = () => {
      toggleFullScreen();
      trackClickedVideoFullScreenButton();

      if (isFullScreen) {
        trackExitedFullScreen({ location: 'video' });
      } else {
        trackEnteredFullScreen({ location: 'video' });
      }
    };

    const renderCenteredIcon = () => {
      if (isMobileAgent) {
        return null;
      }

      if (!isSeeking && showLoadingIndicator) {
        return (
          <div className="pointer-events-none absolute inset-x-0 flex size-full items-center justify-center">
            <Spinner className="text-blue-9" />
          </div>
        );
      }

      if (!isViewingAnnotatedComment && !isAnnotationsEnabled && !isRewinding) {
        return (
          <button
            className="absolute inset-x-0 flex size-full cursor-default items-center justify-center bg-transparent text-white"
            onClick={isPlaying ? pause : play}
            style={{ zIndex: isFullScreen ? 'auto' : BIG_PLAY_Z_INDEX }}
          >
            {!isPlaying && (
              <FilledPlay className="size-16 cursor-pointer" style={{ zIndex: BIG_PLAY_Z_INDEX }}>
                Play button
              </FilledPlay>
            )}
          </button>
        );
      }

      return null;
    };

    const onChangeTimecode = useCallback(
      (value: number) => {
        if (format === VideoTimeFormat.Frames) {
          onUpdateFrame(value);
        } else {
          ClipUtils.changeVideoTimeInSeconds(videoElement.current!, value);
          setQueryParams(
            {
              [QueryParamNames.timestamp]: videoElement.current?.currentTime || 0,
              [QueryParamNames.discussionId]: null,
            },
            'replace',
          );
        }
      },
      [format, onUpdateFrame],
    );

    const { onSeek, onSeekEnd, onSeekStart } = useVideoSeek<State>({
      clip,
      isPlaying,
      pause,
      play,
      setShouldResetVideo,
      setState,
      videoElement,
    });

    const onMarkerClick = useCallback(
      (markerTimestamp: number) => {
        setShouldResetVideo(false);

        const video = videoElement.current;

        if (!video) return;

        setQueryParams(
          {
            [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
            [QueryParamNames.timestamp]: markerTimestamp,
            [QueryParamNames.discussionId]: null,
          },
          'replace',
        );
        showPanel(AssetModalPanel.COMMENTS);
        pause();
        video.currentTime = markerTimestamp;
        setAnnotationsEnabled(true);
        _onMarkerClick?.(markerTimestamp);

        trackSelectedComment({
          location: 'video timeline',
          has_timestamp: true,
          has_annotation: !!activeAnnotation || false,
        });
      },
      [_onMarkerClick, activeAnnotation, pause, setAnnotationsEnabled, showPanel, trackSelectedComment],
    );

    const renderTimestamp = useCallback(() => {
      return (
        <VideoTimestamp
          activeDiscussionId={activeDiscussionId}
          duration={duration || clip.duration || 0}
          markers={markers}
          onMarkerClick={onMarkerClick}
        />
      );
    }, [activeDiscussionId, clip.duration, duration, markers, onMarkerClick]);

    return (
      <div
        className={classNames(
          'flex h-full w-full flex-col items-start justify-start',
          isFullScreen ? 'fixed left-0 top-0 z-[1000] h-[100vh] w-[100vw] bg-black !pb-0' : undefined,
        )}
        style={{ paddingBottom: isMobileAgent ? 0 : 92 }}
        ref={outerContainer}
      >
        {ClipUtils.isProcessing(clip.status) && <ProcessingMessage />}
        <div
          className="m-auto flex size-full select-none items-center justify-center overflow-hidden"
          ref={videoContainer}
        >
          <div className="relative flex size-full max-h-full items-center justify-center">
            {showPlaybackAlert && !!playbackSpeed && <VideoPlaybackSpeedAlert playbackSpeed={playbackSpeed} />}
            {/* While the video is rewinding, the normal play/pause toggle doesn't work so we need to overlay the video with an invisible div */}
            {!!playbackSpeed && playbackSpeed < 0 && (
              <div className="absolute inset-0 z-10" onClick={onToggleVideoState} />
            )}

            <FilePlayer
              className="flex h-full rounded object-contain will-change-transform"
              style={{
                maxWidth: isAboveMediumScreen
                  ? !clientWidth || clip.width > clientWidth
                    ? '100%'
                    : clip.width
                  : '100%',
                maxHeight: isAboveMediumScreen
                  ? !clientHeight || clip.height > clientHeight
                    ? '100%'
                    : clip.height
                  : 'unset',
                opacity: isSeeking && !isMobileAgent ? 0.2 : 1,
              }}
              type={clip.type === 'audio' && isMobileAgent ? 'audio' : 'video'}
              data-testid={VIDEO}
              onCanPlay={clearLoadVideoTime}
              playsInline
              ref={(ref: HTMLVideoElement | null) => {
                if (ref) {
                  videoElement.current = ref;
                  setVideoEl(ref);
                }
              }}
              preload="auto"
              controls={isMobileAgent}
              src={clip.type === 'audio' && isMobileAgent ? clip.assets.original : clip.assets.video}
              poster={clip.assets.image}
            />
            {/* Mobile uses default video controls. DO NOT COVER */}
            {!isMobileAgent && (
              <Annotations
                width={videoWidth ?? 0}
                height={videoHeight ?? 0}
                pageNumber={-1}
                keyShortcutsEnabled={canDrawAnnotation}
                canDrawAnnotation={canDrawAnnotation}
                onIsSelectingChange={pause}
                hideAnnotations={isPlaying}
              />
            )}
            {renderCenteredIcon()}
          </div>
        </div>
        {videoElement.current && !isMobileAgent && (
          <div
            className={classNames(
              'absolute bottom-0 left-0 flex w-full flex-col items-center justify-center',
              isFullScreen ? 'bottom-6 pt-0' : undefined,
            )}
            ref={controlsRef}
          >
            <div
              className={classNames(
                'relative w-full grow px-3 pb-3',
                isFullScreen ? 'rounded-tl-lg rounded-tr-lg bg-black/60 pt-9 backdrop-blur' : undefined,
              )}
              style={{
                maxWidth: isFullScreen ? FULLSCREEN_SEEKBAR_MAXWIDTH : undefined,
              }}
            >
              <VideoSeekbar
                clip={clip}
                duration={duration}
                onSeek={onSeek}
                onSeekEnd={onSeekEnd}
                onSeekStart={onSeekStart}
                processedPercentage={processedPercentage}
                progressPercentage={progressPercentage}
                renderTimestamp={renderTimestamp}
              />
            </div>

            <div
              onClick={(e) => e.stopPropagation()}
              className={classNames('relative flex w-full flex-row items-center px-3 pb-2', {
                'rounded-b-lg bg-black/60 backdrop-blur': isFullScreen,
              })}
              data-testid={VIDEO_CONTROLS_WRAPPER}
              style={{
                zIndex: ACTIVE_TIMESTAMP_MARKER_Z_INDEX + 1,
                maxWidth: isFullScreen ? FULLSCREEN_SEEKBAR_MAXWIDTH : 'unset',
              }}
            >
              <div className="flex flex-1 flex-row items-center">
                <PlayPauseButton isPlaying={isPlaying || isRewinding} onPlayPause={isPlaying ? pause : play} />
                <LoopVideoButton shouldLoopVideo={shouldLoopVideo} toggleLoopVideo={toggleLoopVideo} />
                <PlaybackSpeedOptions
                  container={isFullScreen ? outerContainer.current : undefined}
                  onSelectPlaybackSpeed={onSelectPlaybackSpeed}
                  currentPlaybackSpeed={playbackSpeed || currentPlaybackSpeed}
                />
              </div>
              <div className="flex flex-1 items-center justify-center">
                <Tooltip label={`Go backward 1 ${format === VideoTimeFormat.Frames ? 'frame' : 'second'}`}>
                  <IconButton
                    appearance="ghost"
                    color="grey"
                    onClick={() => onChangeTimecode(-1)}
                    icon={ChevronLeft}
                    size="small"
                    label="Chevron Left"
                  />
                </Tooltip>
                <VideoTimeFormatMenu
                  currentTime={videoElement.current.currentTime}
                  frameCount={clip.frameCount}
                  totalTime={clip.duration}
                />
                <Tooltip label={`Go forward 1 ${format === VideoTimeFormat.Frames ? 'frame' : 'second'}`}>
                  <IconButton
                    appearance="ghost"
                    color="grey"
                    onClick={() => onChangeTimecode(1)}
                    icon={ChevronRight}
                    size="small"
                    label="Chevron Right"
                  />
                </Tooltip>
              </div>
              <div className="flex flex-1 items-center justify-end">
                <VolumeControl
                  container={isFullScreen ? outerContainer.current : undefined}
                  muted={muted}
                  volume={volume}
                  toggleMuted={toggleMuted}
                  onVolumeChange={onVolumeChange}
                />
                <VideoQualityOptions
                  container={isFullScreen ? outerContainer.current : undefined}
                  onSelectVideoResolution={onSelectVideoResolution}
                  currentResolutionIndex={currentResolutionIndex}
                  resolutionOptions={resolutionOptions}
                />
                <FullScreenButton
                  onFullScreenToggle={onToggleFullScreen}
                  isFullScreen={isFullScreen}
                  iconText={isFullScreen ? 'Full screen exit' : 'Full screen enter'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);

VideoVisualizerInner.displayName = 'VideoVisualizerInner';
