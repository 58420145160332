import type { Clip } from '@air/api/types';
import { memo, useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';
import { useResizeDetector } from 'react-resize-detector';

export type VideoFrameCountProps = {
  currentTime: number;
  frameCount: Required<Clip>['frameCount'];
  totalTime: number;
};

export const VideoFrameCount = memo(({ currentTime, frameCount, totalTime }: VideoFrameCountProps) => {
  const countRef = useRef<HTMLDivElement>(null);
  const { ref: resizeRef, width } = useResizeDetector({
    handleHeight: false,
  });
  const { update } = useCountUp({
    ref: countRef,
    duration: 0.5,
    end: frameCount || 0,
  });

  useEffect(() => {
    update(Math.floor((currentTime / totalTime) * frameCount));
  }, [currentTime, frameCount, totalTime, update]);

  return (
    <div
      className="flex items-center gap-1 text-14 text-grey-11"
      data-testid="VIDEO_FRAME_COUNT"
      style={{
        fontFeatureSettings: "'tnum' on",
      }}
    >
      <span
        className="text-right font-medium text-grey-12"
        data-testid="VIDEO_FRAME_COUNT_CURRENT"
        ref={countRef}
        style={{ minWidth: width }}
      />
      <span>/</span>
      <span data-testid="VIDEO_FRAME_COUNT_TOTAL" ref={resizeRef}>
        {frameCount ? frameCount.toLocaleString() : '-'}
      </span>
    </div>
  );
});

VideoFrameCount.displayName = 'VideoFrameCount';
