import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ANNOTATION_COMMENT_ID } from '~/components/Annotations/AnnotationsAvatarsContainer/AnnotationAvatarContainer/components/AnnotationComment';
import { QueryParamNames } from '~/constants/search';
import { useAnnotationContext, useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import { hasNewAnnotationSelector } from '~/providers/AnnotationProvider/annotationProviderUtils';
import { isViewingAnnotatedCommentSelector } from '~/store/router/selectors';
import { setQueryParams } from '~/utils/PathUtils';

export const useClearAnnotationOnMouseDownOutside = () => {
  const { clearNewAnnotation } = useAnnotationContext();
  const hasNewAnnotation = useAnnotationContextSelector(hasNewAnnotationSelector);
  const hasActiveDiscussion = useSelector(isViewingAnnotatedCommentSelector);
  const clickTimeoutId = useRef(0);

  useEffect(() => {
    const clearDiscussion = (event: MouseEvent) => {
      // do not clear discussion when clicked on a button, e.g. on zoomIn/zoomOut
      if (
        !event.defaultPrevented &&
        !event.composedPath().some((el) => {
          const element = el as HTMLElement;
          const elementTagName = element.tagName?.toLowerCase();
          const elementId = element.id;
          return (
            elementId === ANNOTATION_COMMENT_ID ||
            elementTagName === 'button' ||
            elementTagName === 'input' ||
            elementTagName === 'textarea'
          );
        })
      ) {
        setQueryParams({ [QueryParamNames.discussionId]: null }, 'replace');
        clearNewAnnotation();
      }
    };

    if (hasActiveDiscussion || hasNewAnnotation) {
      // set a listener after a short timeout to prevent cancelling newly created annotation
      clickTimeoutId.current = window.setTimeout(() => window.addEventListener('mousedown', clearDiscussion), 50);
    }

    return () => {
      clearTimeout(clickTimeoutId.current);
      window.removeEventListener('mousedown', clearDiscussion);
    };
  }, [clearNewAnnotation, hasActiveDiscussion, hasNewAnnotation]);
};
