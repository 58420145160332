import { useTrackClickedPublicBoardLink } from '@air/analytics';
import Router from 'next/router';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PublicTopBarAccountInfo } from '~/components/Layouts/PublicBoardLayout/PublicTopBarAccountInfo';
import { PublicBoardView } from '~/components/PublicBoard/PublicBoardView';
import { PublicBoardActivityContainer } from '~/components/PublicBoardActivityContainer';
import { PublicBoardAssetModal } from '~/components/PublicBoardAsset/PublicBoardAssetModal';
import { PUBLIC_TOP_BAR_HEIGHT, PublicTopBar } from '~/components/PublicTopBar';
import { PublicWorkspaceInfo } from '~/components/PublicWorkspaceInfo';
import { PublicWorkspaceNavigation } from '~/components/PublicWorkspaceNavigation';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { MainContent } from '~/components/Shared/ClipGallery/Content';
import { PublicHeaderBar } from '~/components/Shared/HeaderBar/PublicHeaderBar';
import { createBoardRoute } from '~/constants/routes';
import { MAIN, PUBLIC_BOARD_CHILDREN_CONTAINER } from '~/constants/testIDs';
import { useRerenderOnMount } from '~/hooks/useRerenderOnMount';
import { useURLClipIdSelector } from '~/hooks/useURLClipIdSelector';
import { useAccountContext } from '~/providers/AccountProvider';
import { AnnotationProvider } from '~/providers/AnnotationProvider/AnnotationProvider';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';
import { useAirStore } from '~/utils/ReduxUtils';

export const ViewableBoard = memo(() => {
  const store = useAirStore();
  const { data: account } = useAccountContext();
  const { data: workspaces } = useWorkspaces();
  const { workspaceId } = usePublicWorkspace();
  const mainRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const { trackClickedPublicBoardLink } = useTrackClickedPublicBoardLink();
  const clipId = useURLClipIdSelector();
  const { isMounted } = useRerenderOnMount();

  const [isVisitBannerVisible, setVisitBannerVisible] = useState(() => {
    if (!workspaces || !workspaceId) return false;
    return workspaces.some((workspace) => workspace.id === workspaceId);
  });

  useEffect(() => {
    if (isVisitBannerVisible || !workspaceId || !workspaces) return;

    setVisitBannerVisible(workspaces.some((workspace) => workspace.id === workspaceId));
  }, [workspaceId, isVisitBannerVisible, workspaces]);

  const onWorkspaceNavigate = useCallback(() => {
    const centralizedBoard = centralizedBoardSelector(store.getState());
    if (!centralizedBoard) return;
    Router.push(createBoardRoute(centralizedBoard.id, centralizedBoard.title));
    trackClickedPublicBoardLink({
      name: 'clicked view in workspace',
      page_area: 'banner',
      page_type: 'public board',
    });
  }, [store, trackClickedPublicBoardLink]);

  const workspaceInfo = useMemo(
    () =>
      isVisitBannerVisible ? (
        <PublicWorkspaceNavigation onWorkspaceNavigate={onWorkspaceNavigate} />
      ) : (
        <PublicWorkspaceInfo />
      ),
    [isVisitBannerVisible, onWorkspaceNavigate],
  );

  return (
    <>
      <PublicTopBar
        workspaceInfo={workspaceInfo}
        accountInfo={account ? <PublicTopBarAccountInfo account={account} /> : null}
      />

      <MainContent
        id="scrollContainer"
        data-testid={MAIN}
        ref={mainRef}
        style={{ height: `calc(100% - ${PUBLIC_TOP_BAR_HEIGHT}px)` }}
      >
        <div className="flex h-fit flex-1 flex-col">
          <PublicHeaderBar headerRef={headerRef} />
          <div className="grow" data-testid={PUBLIC_BOARD_CHILDREN_CONTAINER}>
            <PublicBoardView headerRef={headerRef} scrollElementRef={mainRef} />
            {/*
              We check here if isMounted is true or not because clipId is always false on the server but is there
              on first render client side and this check prevents a rehydration issue
            */}
            {!!clipId && isMounted && (
              <AnnotationProvider>
                <PublicBoardAssetModal />
              </AnnotationProvider>
            )}
          </div>
        </div>
        <PublicBoardActivityContainer />
      </MainContent>
    </>
  );
});

ViewableBoard.displayName = 'ViewableBoard';
