import { type Clip } from '@air/api/types';
import { type RefObject, SetStateAction, useCallback } from 'react';

import { QueryParamNames } from '~/constants/search';
import ClipUtils from '~/utils/ClipUtils';
import { setQueryParams } from '~/utils/PathUtils';

let wasPlayingBeforePreSeek = false;

export type UseVideoSeekParams<State> = {
  clip: Clip;
  isPlaying: boolean;
  pause: () => void;
  play: () => void;
  setState: (value: SetStateAction<State>) => void;
  setShouldResetVideo: (shouldResetVideo: boolean) => void;
  videoElement: RefObject<HTMLVideoElement>;
};

export const useVideoSeek = <State>({
  clip,
  isPlaying,
  pause,
  play,
  setShouldResetVideo,
  setState,
  videoElement,
}: UseVideoSeekParams<State>) => {
  const onSeek = useCallback(
    (value: number) => {
      const isProcessing = ClipUtils.isProcessing(clip.status);
      const video = videoElement.current;

      setShouldResetVideo(false);

      if (!video) return;

      if (isProcessing) {
        const duration = clip?.duration || 0;
        const processedDuration = video.duration || 0;
        const maxSeekPercentage = processedDuration / duration;
        const BUFFER_TIME = 6;

        if (value > maxSeekPercentage) {
          video.currentTime = maxSeekPercentage * (duration - BUFFER_TIME);

          setState((prev) => ({
            ...prev,
            progressPercentage: maxSeekPercentage,
          }));
        } else {
          video.currentTime = value * duration;
          setState((prev) => ({
            ...prev,
            progressPercentage: value,
          }));
        }
      } else {
        const videoDuration = video.duration || 0;

        video.currentTime = value * videoDuration;

        setState((prev) => ({
          ...prev,
          progressPercentage: value,
        }));
      }
    },
    [clip?.duration, clip.status, setShouldResetVideo, setState, videoElement],
  );

  const onSeekEnd = useCallback(
    (value: number) => {
      if (wasPlayingBeforePreSeek) {
        play();
      }
      const video = videoElement.current;
      if (!video) return;
      const videoDuration = video.duration || 0;

      setQueryParams({ [QueryParamNames.timestamp]: Math.max(0, value * videoDuration) }, 'replace');
      video.currentTime = value * videoDuration;

      setState((prev) => ({
        ...prev,
        isSeeking: false,
        progressPercentage: value,
      }));
    },
    [play, setState, videoElement],
  );

  const onSeekStart = useCallback(() => {
    setShouldResetVideo(false);
    wasPlayingBeforePreSeek = isPlaying;
    if (isPlaying) {
      pause();
    }
    setState((prev) => ({
      ...prev,
      isSeeking: true,
    }));
    setQueryParams({ [QueryParamNames.timestamp]: null, [QueryParamNames.discussionId]: null }, 'replace');
  }, [isPlaying, pause, setShouldResetVideo, setState]);

  return {
    onSeek,
    onSeekEnd,
    onSeekStart,
  };
};
