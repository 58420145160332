import { PublicClip as PublicClipInterface, ScopedPermissions } from '@air/api';
import { ModalProvider } from '@air/provider-modal';
import { ShortIdProvider } from '@air/provider-short-id';
import { ErrorBoundary } from '@air/utils-error';
import { ConnectedRouter } from 'connected-next-router';

import { AccountProvisionerProvider } from '~/components/AccountProvisioner/providers/AccountProvisionerProvider';
import { MaintenanceMode } from '~/components/MaintenanceMode/MaintenanceMode';
import { PublicAsset } from '~/components/PublicAssetModal/PublicAsset';
import { PublicAssetReduxProvider } from '~/components/PublicAssetReduxProvider';
import { PublicClipActivityContainer } from '~/components/PublicClipActivityContainer';
import { PublicClipSocketContextProvider } from '~/components/PublicClipSocketContextProvider';
import { PublicPermissionsProvider } from '~/components/PublicPermissionsProvider';
import { PublicWorkspaceProvider } from '~/components/PublicWorkspaceProvider';
import { PublicAccountProvider } from '~/providers/AccountProvider';
import { AnnotationProvider } from '~/providers/AnnotationProvider/AnnotationProvider';
import { FiltersProvider } from '~/providers/FiltersProvider';
import { PublicClipCustomFieldsProvider } from '~/providers/PublicClipCustomFieldsProvider';

import { PublicAssetSEO } from '../PublicBoardLayout/PublicAssetSEO';

export interface PublicClipProps {
  initialData: {
    clip: PublicClipInterface;
    permissions: ScopedPermissions;
    requireAccount: boolean;
  };
  shortId: string;
}

export const PublicClip = ({ shortId, initialData }: PublicClipProps) => {
  return (
    <AccountProvisionerProvider>
      <PublicAccountProvider>
        <PublicPermissionsProvider permissions={initialData.permissions}>
          <PublicAssetReduxProvider initialClip={initialData.clip}>
            <ShortIdProvider shortId={shortId} objectId={initialData.clip.id} objectType="clip">
              <PublicWorkspaceProvider {...initialData.clip}>
                <ErrorBoundary>
                  <PublicClipSocketContextProvider>
                    <ConnectedRouter>
                      <FiltersProvider>
                        <ModalProvider>
                          <MaintenanceMode>
                            <PublicClipCustomFieldsProvider>
                              <PublicAssetSEO clip={initialData.clip} />
                              <AnnotationProvider>
                                <PublicAsset
                                  requireAccount={initialData.requireAccount}
                                  initialClip={initialData.clip}
                                />
                              </AnnotationProvider>
                              <PublicClipActivityContainer />
                            </PublicClipCustomFieldsProvider>
                          </MaintenanceMode>
                        </ModalProvider>
                      </FiltersProvider>
                    </ConnectedRouter>
                  </PublicClipSocketContextProvider>
                </ErrorBoundary>
              </PublicWorkspaceProvider>
            </ShortIdProvider>
          </PublicAssetReduxProvider>
        </PublicPermissionsProvider>
      </PublicAccountProvider>
    </AccountProvisionerProvider>
  );
};
