import { Clip } from '@air/api/types';
import { memo, useMemo } from 'react';

import { VideoTimecodeFrames } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/components/VideoTimecodeFrames';
import { formatTimecodeValue, getTimecode } from '~/utils/VideoUtils';

export type VideoTimecode = {
  currentTime: number;
  frameCount?: Clip['frameCount'];
  totalTime?: number;
};

export const VideoTimecode = memo(({ currentTime, frameCount, totalTime }: VideoTimecode) => {
  const { hours, minutes, seconds, frames } = useMemo(() => {
    return getTimecode({
      currentTime,
      frameCount,
      totalTime,
    });
  }, [currentTime, frameCount, totalTime]);

  return (
    <div
      className="text-14 text-grey-11"
      data-testid="VIDEO_TIMECODE"
      style={{
        fontFeatureSettings: "'tnum' on",
      }}
    >
      <span>{formatTimecodeValue(hours)}</span>
      <span>:</span>
      <span>{formatTimecodeValue(minutes)}</span>
      <span>:</span>
      <span>{formatTimecodeValue(seconds)}</span>
      {!!frames && !!frameCount && (
        <>
          <span>:</span>
          <VideoTimecodeFrames frames={frames} frameCount={frameCount} />
        </>
      )}
    </div>
  );
});

VideoTimecode.displayName = 'VideoTimecode';
