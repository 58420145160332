import { type Clip } from '@air/api/types';
import { Check, Info } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { Tooltip } from '@air/primitive-tooltip';
import { memo, useMemo } from 'react';

import { VideoFrameCount } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/components/VideoFrameCount';
import { VideoStandardTime } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/components/VideoStandardTime';
import { VideoTimecode } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/components/VideoTimecode';
import { useVideoTimeFormatContext, VideoTimeFormat } from '~/providers/VideoTimeFormatProvider';

export type VideoTimeFormatMenuProps = {
  currentTime: number;
  frameCount: Clip['frameCount'];
  totalTime?: number;
};

export const VideoTimeFormatMenu = memo(({ currentTime, frameCount, totalTime }: VideoTimeFormatMenuProps) => {
  const { format, handleUpdateFormat } = useVideoTimeFormatContext();

  const formatOptions = useMemo(() => {
    const formatOptionsArray: DropdownMenuOption[] = [
      {
        id: 'time-format',
        label: 'Time Format',
        type: 'title',
      },
      {
        id: VideoTimeFormat.Standard,
        label: 'Standard',
        onSelect: () => handleUpdateFormat(VideoTimeFormat.Standard),
        suffix: format === VideoTimeFormat.Standard ? <Check className="size-4 text-blue-9" /> : undefined,
        type: 'item',
      },
      {
        id: VideoTimeFormat.Frames,
        disabled: !frameCount,
        label: 'Frames',
        onSelect: () => handleUpdateFormat(VideoTimeFormat.Frames),
        suffix: !frameCount ? (
          <Tooltip delayDuration={0} label="Frame count is not available for this video">
            <Info className="hidden size-4 group-hover/dropdown-menu-item:block" />
          </Tooltip>
        ) : format === VideoTimeFormat.Frames ? (
          <Check className="size-4 text-blue-9" />
        ) : undefined,
        type: 'item',
      },
      {
        id: VideoTimeFormat.Timecode,
        label: 'Timecode',
        onSelect: () => handleUpdateFormat(VideoTimeFormat.Timecode),
        suffix: format === VideoTimeFormat.Timecode ? <Check className="size-4 text-blue-9" /> : undefined,
        type: 'item',
      },
    ];

    return formatOptionsArray;
  }, [format, frameCount, handleUpdateFormat]);

  return (
    <DropdownMenu
      trigger={
        <Button
          appearance="ghost"
          color="grey"
          data-testid="VIDEO_TIME_FORMAT"
          style={{
            fontFeatureSettings: "'tnum' on",
          }}
          size="small"
        >
          {format === VideoTimeFormat.Frames && frameCount && totalTime ? (
            <VideoFrameCount currentTime={currentTime} frameCount={frameCount} totalTime={totalTime} />
          ) : format === VideoTimeFormat.Timecode ? (
            <VideoTimecode currentTime={currentTime} frameCount={frameCount} totalTime={totalTime} />
          ) : (
            <VideoStandardTime currentTime={currentTime} totalTime={totalTime} />
          )}
        </Button>
      }
    >
      {renderDropdownItems({ options: formatOptions })}
    </DropdownMenu>
  );
});

VideoTimeFormatMenu.displayName = 'VideoTimeFormatMenu';
