import { isError } from 'lodash';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
  EditableAssetModalTitle,
  EditableAssetModalTitleProps,
} from '~/components/AssetModal/components/EditableAssetModalTitle';
import {
  centralizedClipAssetIdSelector,
  centralizedClipIdSelector,
  centralizedDisplayTitleSelector,
} from '~/store/centralizedClip/selectors';
import { useUpdateAssetTitle } from '~/swr-hooks/clips/useUpdateAssetTitle';
import { containsRestrictedPathChars } from '~/utils/FileUtils';

export const AssetTitleValidationSchema = Yup.object().shape({
  ['title']: Yup.string()
    .max(255, 'Cannot be longer than 255 characters')
    .required('Title is required')
    .test(
      'restricted-char-validation',
      'Characters : and | cannot be used',
      (val) => !containsRestrictedPathChars(val || ''),
    )
    .matches(/^[^\n]*$/, 'No line breaks allowed'),
});

export const validateAssetTitle = (value: string) => {
  try {
    AssetTitleValidationSchema.validateSync({ title: value });
  } catch (error) {
    return isError(error) ? error.message : 'Invalid title';
  }
};

export const EditableAssetModalHeaderTitle = memo(() => {
  const clipId = useSelector(centralizedClipIdSelector);
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const title = useSelector(centralizedDisplayTitleSelector);
  const { updateAssetTitle } = useUpdateAssetTitle();

  const onSubmit = useCallback<Required<EditableAssetModalTitleProps>['onSubmit']>(
    (newTitle) => {
      if (title !== newTitle) {
        updateAssetTitle(clipId, newTitle, assetId);
      }
    },
    [assetId, clipId, title, updateAssetTitle],
  );

  return (
    <div className="flex">
      <EditableAssetModalTitle
        className="grow-0"
        defaultValue={title}
        onSubmit={onSubmit}
        validate={validateAssetTitle}
      />
    </div>
  );
});

EditableAssetModalHeaderTitle.displayName = 'EditableAssetModalHeaderTitle';
