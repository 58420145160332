import { InlineInput, InlineInputProps } from '@air/component-inline-input';
import { Button } from '@air/primitive-button';
import { tailwindMerge } from '@air/tailwind-variants';
import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';

export type EditableAssetModalTitleProps = Pick<
  InlineInputProps,
  'onSubmit' | 'defaultValue' | 'validate' | 'className'
>;

export const EditableAssetModalTitle = memo(
  ({ onSubmit, defaultValue, validate, className }: EditableAssetModalTitleProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    return (
      <div className={tailwindMerge('relative', className)} data-testid="ASSET_TITLE">
        <Button
          data-testid="EDITABLE_ASSET_TITLE_BUTTON"
          className={classNames('h-8 w-full text-18 md:h-9 md:text-20', !value && 'min-w-[130px] md:min-w-[150px]')}
          appearance="ghost"
          color="grey"
          onClick={() => setIsEditing(true)}
        >
          <span className="truncate">{value}</span>
        </Button>
        {isEditing && (
          <div className="absolute inset-0 ml-px">
            <InlineInput
              rows={1}
              allowNewLines={false}
              label="Asset name"
              onSubmit={(value) => {
                setIsEditing(false);
                onSubmit?.(value);
              }}
              onCancel={() => {
                setValue(defaultValue);
                setIsEditing(false);
              }}
              onChange={setValue}
              inputClassName="font-semibold text-18 md:text-20 h-8 md:h-9 md:py-0.5 leading-[30px] md:leading-[inherit]"
              defaultValue={value}
              data-testid="ASSET_TITLE_INPUT"
              placeholder="Enter a name"
              validate={validate}
            />
          </div>
        )}
      </div>
    );
  },
);

EditableAssetModalTitle.displayName = 'EditableAssetModalTitle';
